import * as React from 'react';
import * as moment from 'moment';
import type { States } from '@@services/services';
import { AppTextLabel } from '@inwink/i18n';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { CountDown } from '@@components/countdown';
import type { ICommunityWebinar } from '@@community/data';
import { withRealtimeFeature, IRealtimeManager } from '@@community/components/communityrealtimefeature';
import { Player } from './player';

import "./webinarplayer.less";

export interface IWebinarPlayerOptions {

}

export interface IWebinarPlayerProps {
    entityKind: string;
    realtime?: Promise<IRealtimeManager>;
    community?: States.ICommunityState;
    currentuser?: States.IAppUserState;
    i18n?: States.i18nState;
    webinar: ICommunityWebinar;
    passive?: boolean;
    options?: IWebinarPlayerOptions;
    isTeaser?: boolean;
    onWebinarStart?: () => void;
    onWebinarApproaching?: () => void;
    onWebinarEnd?: () => void;
    children?: React.ReactNode;
    hidden?: boolean;
}

export interface IWebinarPlayerState {
    broadcastUrl: string;
    broadcastEnded?: boolean;
    eventKey: string;
    stateChanged?: Date;
}

@connectwith((state: States.IAppState) => {
    return {
        community: state.community,
        currentuser: state.user,
        i18n: state.i18n
    };
})
@withRealtimeFeature("default")
export class WebinarPlayer extends React.PureComponent<IWebinarPlayerProps, IWebinarPlayerState> {
    checkInterval: any;

    approachNotification = false;

    constructor(props: IWebinarPlayerProps) {
        super(props);
        this.checkWebinarDates(props.webinar);
        this.state = {
            eventKey: ("inwink.entity."
                + this.props.community.communityid + "."
                + this.props.entityKind + "."
                + this.props.webinar.id
            ).toLowerCase(),
            broadcastUrl: props.webinar.broadcastUrl // || 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8'
        };
    }

    clearCheck() {
        if (this.checkInterval) {
            clearInterval(this.checkInterval);
            this.checkInterval = null;
        }
    }

    webinarStatusUpdate = (evt) => {
        if (evt?.detail?.entityOperation === "startbroadcast") {
            this.clearCheck();
            this.setState({ broadcastUrl: evt.detail.broadcastUrl });
            if (this.props.onWebinarStart) {
                this.props.onWebinarStart();
            }
        } else if (evt?.detail?.entityOperation === "stopbroadcast") {
            this.clearCheck();
            // on attend 10 secondes avant de passer le live en terminé
            // pour que le visiteur puisse voir la fin du live (a cause du délais)
            setTimeout(() => {
                this.setState({ broadcastUrl: null, broadcastEnded: true });
                if (this.props.onWebinarEnd) {
                    this.props.onWebinarEnd();
                }
            }, 10000);
        }
        console.log("receive session update", evt);
    };

    checkWebinar = () => {
        const minutesToWebinar = this.props.webinar?.$startDate && typeof this.props.webinar.$startDate.diff === 'function'
            ? this.props.webinar.$startDate.diff(moment(), "minutes") : null;
        if (minutesToWebinar < 15) {
            if (!this.approachNotification) {
                this.approachNotification = true;

                if (this.props.onWebinarApproaching) {
                    this.props.onWebinarApproaching();
                }
                this.setState({});
            }
        }
    };

    componentDidMount() {
        if (this.state.broadcastUrl) {
            if (this.props.isTeaser && this.props.onWebinarStart) {
                this.props.onWebinarStart();
            }
        } else {
            this.checkWebinar();
            this.checkInterval = setInterval(this.checkWebinar, 30000);
        }

        this.props.realtime.then((mgr) => mgr.attachEntity(
            this,
            this.props.community.communityid,
            "IWEvent",
            this.props.webinar.id
        ));
        window.addEventListener(this.state.eventKey, this.webinarStatusUpdate);
    }

    componentWillUnmount() {
        window.removeEventListener(this.state.eventKey, this.webinarStatusUpdate);
        this.props.realtime.then((mgr) => mgr.detachEntity(
            this,
            this.props.community.communityid,
            "IWEvent",
            this.props.webinar.id
        ));
    }

    componentDidUpdate() {
        const props = this.props;
        this.checkWebinarDates(props.webinar);
    }

    onCountDownExpires = () => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ stateChanged: new Date() });
    };

    checkWebinarDates(webinar: ICommunityWebinar) {
        if (webinar.startDate && !webinar.$startDate) {
            webinar.$startDate = moment(webinar.startDate);
        }
        if (webinar.endDate && !webinar.$endDate) {
            webinar.$endDate = moment(webinar.endDate);
        }
    }

    render() {
        if (this.props.hidden) return null;
        let content = null;
        const { webinar } = this.props;
        this.checkWebinarDates(webinar);
        if (this.state.broadcastUrl) {
            if (this.props.isTeaser) {
                content = this.props.children;
            } else {
                content = <Player
                    community={this.props.community}
                    webinar={this.props.webinar}
                    url={this.state.broadcastUrl}
                    passive={this.props.passive}
                />;
            }
        } else if (this.props.webinar.vodId || this.state.broadcastEnded ||
            this.props.webinar.$endDate?.isBefore && this.props.webinar?.$endDate?.isBefore(moment())) {
            content = <div className="usermessage">
                <AppTextLabel i18n="community.content.webinar.ended" />
            </div>;
        } else if (moment().isSameOrAfter(this.props.webinar.$startDate, "seconds")) {
            content = <div className="usermessage">
                <div className="message">
                    <AppTextLabel i18n="community.content.webinar.immediate" component="h3" />
                </div>
            </div>;
        } else {
            content = <div className="usermessage">
                <div className="message">
                    <AppTextLabel i18n="community.content.webinar.waiting" component="h3" />
                    <CountDown date={this.props.webinar.$startDate} onPassedDate={this.onCountDownExpires} />
                </div>
            </div>;
        }

        return <div className="webinarplayer">
            {content}
        </div>;
    }
}
