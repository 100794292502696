import * as React from 'react';
import type { States } from '@@services/services';
import { HLSPlayer } from '@@modules/livebroadcast/hlsplayer';
import { PrecallLite } from "@@modules/videochat/providers/vonage/precalllite";
import { ICommunityWebinar } from '@@community/data';
import { trackWatch } from '@@community/api/contents';

import "./player.less";

export interface IPlayerProps {
    community: States.ICommunityState;
    webinar: ICommunityWebinar;
    url: string;
    passive: boolean;
}

export class Player extends React.PureComponent<IPlayerProps, any> {
    isIOS = false;

    constructor(props) {
        super(props);

        const ua = navigator?.userAgent;
        this.isIOS = /iPad|iPhone|iPod/.test(ua);

        this.state = {
        };
    }

    componentDidMount() {
        if (!this.props.passive) {
            trackWatch(this.props.community.requestManagers.apiFront, "IWEvent", this.props.webinar.id);
        }
    }

    render() {
        const url = this.props.url;
        // const url = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
        if (url) {
            if (this.isIOS) {
                return (
                    <div className="community-webinarplayer">
                        <PrecallLite>
                            <HLSPlayer
                                src={url}
                                previewOnly={this.props.passive}
                            />
                        </PrecallLite>
                    </div>
                );
            }

            return <div className="community-webinarplayer">
                <HLSPlayer
                    src={url}
                    previewOnly={this.props.passive}
                />
            </div>;
        }

        return null;
    }
}
