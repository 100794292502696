import * as React from 'react';

export interface IHLSPlayerActionsProps {
    video: HTMLVideoElement;
}

export class HLSPlayerActions extends React.Component<IHLSPlayerActionsProps, any> {
    rangeRef = React.createRef<HTMLInputElement>();

    toggleFullScreen = (arg: React.MouseEvent<HTMLButtonElement>) => {
        const video = this.props.video;
        arg.stopPropagation();
        arg.preventDefault();
        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if ((video as any).mozRequestFullScreen) {
            (video as any).mozRequestFullScreen(); // Firefox
        } else if ((video as any).webkitRequestFullscreen) {
            (video as any).webkitRequestFullscreen(); // Chrome and Safari
        }
        this.setState({});
    };

    toogleMute = (arg: React.MouseEvent<HTMLButtonElement>) => {
        const video = this.props.video;
        arg.stopPropagation();
        arg.preventDefault();
        if (video.muted) {
            video.muted = false;
            // video.volume = 1;
        } else {
            video.muted = true;
            // video.volume = 0;
        }
        this.setState({});
    };

    volumeChange = (arg: React.ChangeEvent<HTMLInputElement>) => {
        const video = this.props.video;
        arg.stopPropagation();
        arg.preventDefault();
        video.volume = parseFloat(arg.currentTarget.value);
        video.muted = video.volume <= 0;
        this.setState({});
    };

    render() {
        const video = this.props.video;
        let muteIcon = "inwink-volume";
        const isMuted = video?.muted || video?.volume === 0;
        if (isMuted) {
            muteIcon = "inwink-mute";
        }
        // const ua = navigator?.userAgent;
        // const isIOS = /iPad|iPhone|iPod/.test(ua);
        if (video.canPlayType('application/vnd.apple.mpegurl')) {
            // Dans ce cas, on laisse la main au player natif IOS.
            return null;
        }
        return <div className="hlsplayeractions">
            <div className="hlsplayeractions-content">
                <input
                    type="range"
                    value={"" + video.volume}
                    onChange={this.volumeChange}
                    step="0.1"
                    min="0"
                    max="1"
                />
                <button
                    type="button"
                    onClick={this.toogleMute}
                ><i className={muteIcon} /></button>
                <button
                    type="button"
                    onClick={this.toggleFullScreen}
                ><i className="inwink-expand-screen" /></button>
            </div>
        </div>;
    }
}
