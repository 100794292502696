import * as React from 'react';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { I18NResourcesContext } from '@inwink/i18n/resourcecontext';
import './precall.less';

const vonageTrads = require("@inwink/tradscompanion/vonage.json");

export interface IVonagePrecallLiteProps {
    disabled?: boolean;
    children: any;
}

export const PrecallLite = ({ disabled, children }: IVonagePrecallLiteProps) => {
    const [unlocked, setUnlocked] = React.useState(false as boolean);

    const onUnlock = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        setUnlocked(true);
    };

    let content = null;
    if (disabled || unlocked) {
        content = children;
    } else {
        content = (
            <div className="inwinkvideochatprecalllite">
                <div className="precall-content">
                    <div className="precall-actions">
                        <AsyncButton
                            onClick={onUnlock}
                            className="btnenter"
                            i18nLabel="vonage.precall.enter"
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <I18NResourcesContext resources={vonageTrads}>
            {content}
        </I18NResourcesContext>
    );
};
