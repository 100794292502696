import * as React from 'react';
import { loadAssets } from '@inwink/react-utils/loadassets';
import { HLSPlayerActions } from './hlsplayeractions';
import './hlsplayer.less';

// https://api.support.vonage.com/hc/en-us/articles/6646175315868-Which-HLS-players-can-play-low-latency-HLS-streams-
// const trads = require("@inwink/tradscompanion/videoplayer.json");

// @withI18nHelper()
// @withAppTrads(trads)
// export class HLSPlayerHLSJS extends React.Component<IHLSPlayerProps, any> {
//     render() {
//         return <PlayerLimeplay
//             context={{
//                 getVideoHLSUrl: () => {
//                     return Promise.resolve(this.props.src);
//                 },
//             }}
//             i18nHelper={this.props.i18nHelper}
//         />;
//     }
// }

declare const Hls: any;

export interface IHLSPlayerProps {
    src: string;
    muteVideosOnStart?: boolean;
    initialControls?: boolean;
    previewOnly?: boolean;
}

export interface IHLSPlayerState {
    ready?: boolean;
    hasError: boolean;
    muted: boolean;
}

export class HLSPlayerHLSJS extends React.Component<IHLSPlayerProps, IHLSPlayerState> {
    containerRef = React.createRef<HTMLDivElement>();

    videoRef = React.createRef<HTMLVideoElement>();

    allowNativeHls = false;

    constructor(props: IHLSPlayerProps) {
        super(props);
        
        this.state = {
            ready: false,
            hasError: false,
            muted: false
        };
    }

    componentDidMount() {
        this.allowNativeHls = !!(this.videoRef.current?.canPlayType("application/vnd.apple.mpegurl"));

        loadAssets([{
            type: "js",
            url: "https://cdn.jsdelivr.net/npm/hls.js@latest"
        }]).then(() => {
            this.setState({
                ready: true
            }, () => {
                this.init(this.props.src);
            });
        });
    }

    componentDidUpdate(prevprops: IHLSPlayerProps) {
        if (prevprops.src !== this.props.src) {
            this.init(this.props.src);
        }
    }

    componentWillUnmount() {
        const video = this.containerRef.current.querySelector("#player") as HTMLVideoElement;
        if (video) {
            video.removeEventListener("pause", this.preventPause);
        }
    }

    preventPause = (arg: Event) => {
        // const video = this.props.video;
        const video = this.containerRef.current.querySelector("#player") as HTMLVideoElement;
        arg.stopPropagation();
        arg.preventDefault();
        video.play();
    };

    init(src: string) {
        if (this.state.ready) {
            // this.container.current.innerHTML = "";
            if (src) {
                // this.container.current.innerHTML = '<video id="player" controls autoplay></video>';
                const video = this.containerRef.current.querySelector("#player") as HTMLVideoElement;
                // const playButton = this.containerRef.current.querySelector('#play-button') as HTMLVideoElement;
                if (this.props.muteVideosOnStart) {
                    video.volume = 0;
                }

                if (video.canPlayType('application/vnd.apple.mpegurl')) {
                    video.autoplay = true;
                    video.src = src;
                    video.controls = true;
                    setTimeout(() => {
                        if (video.paused){
                            video.play();
                        }
                        // For start video on IOS, Apple need a user gesture (example, an onClick on a button), more info here :
                        // https://webkit.org/blog/6784/new-video-policies-for-ios/
                        /*
                        playButton.addEventListener('click', () => {
                            video.play();
                        });
                        */
                    }, 100);
                } else if (Hls.isSupported()) {
                    const hls = new Hls();
                    hls.loadSource(src);
                    hls.attachMedia(video);
                    hls.on(Hls.Events.MEDIA_ATTACHED, () => {
                        const isMuted = this.props.previewOnly || this.props.muteVideosOnStart;
                        video.muted = isMuted;
                        this.setState({ muted: isMuted });
                        video.play().catch(() => {
                            // on force le mute pour pouvoir play
                            // DOMException: play() failed because the user didn't interact with the document first
                            video.muted = true;
                            this.setState({ muted: true });
                            video.play();
                        });

                        video.addEventListener("pause", this.preventPause);

                        console.log("video and hls.js are now bound together !");
                        hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
                            console.log("manifest loaded, found " + data.levels.length + " quality level");
                            video.play();
                        });
                    });
                } else {
                    console.error("unsupported browser for HLS");
                    this.setState({ hasError: true });
                }
            }
        }
    }

    render() {
        if (this.state.hasError) {
            return <div className="hlsplayer" ref={this.containerRef} />;
        }

        return <div className={"hlsplayer" + (this.props.initialControls ? "" : " customcontrols")} ref={this.containerRef}>
            <video
                id="player"
                controls={(!this.props.previewOnly && (this.props.initialControls || this.allowNativeHls))}
                ref={this.videoRef}
            />
            {this.videoRef.current
                && !this.props.previewOnly
                && !this.props.initialControls
                && !this.allowNativeHls ? <HLSPlayerActions video={this.videoRef.current} /> : null}
        </div>;
    }
}

export const HLSPlayer = HLSPlayerHLSJS;
